// import Swiper styles

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./../scss/app.scss";
// import "./magnific-popup/core";
// import "./../scss/magnific-popup.css";
// import Swiper JS
import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";
import AOS from "aos";

import AjaxLoadMore_module from "./ajaxLoadMore";
window.AjaxLoadMore = AjaxLoadMore_module;
import "bootstrap";

(function ($) {
	"use strict";

	// Initialize AOS
	AOS.init();

	let scrollRef = 0;
	window.addEventListener("scroll", function () {
		// increase value up to 10, then refresh AOS
		scrollRef <= 10 ? scrollRef++ : AOS.refresh();
	});

	let projectNavigationSwiper;
	let isManualNavigation = false;
	let navigationTimeout;

	function initProjectNavigationSwiper() {
		// Get all navigation sections
		const navigationSections = document.querySelectorAll(".navigation-section");
		const swiperWrapper = document.querySelector(
			".swiper-project-navigation .swiper-wrapper"
		);

		if (swiperWrapper == null) {
			return;
		}

		// Clear existing slides
		swiperWrapper.innerHTML = "";

		// Create slides for each section
		navigationSections.forEach((section, index) => {
			const sectionId = section.id;
			const sectionTitle = section.dataset.sectionTitle;

			const slide = document.createElement("div");
			slide.className = "swiper-slide";
			slide.innerHTML = `<a href="#${sectionId}">${sectionTitle}</a>`;
			swiperWrapper.appendChild(slide);
		});

		if (!projectNavigationSwiper) {
			projectNavigationSwiper = new Swiper(".swiper-project-navigation", {
				loop: false,
				slidesPerView: "auto",
				// spaceBetween: 20,
			});

			// Add click handlers to slides
			projectNavigationSwiper.slides.forEach((slide, index) => {
				slide.addEventListener("click", (e) => {
					e.preventDefault();
					const targetId = slide
						.querySelector("a")
						.getAttribute("href")
						.substring(1);

					// Set the manual navigation flag
					isManualNavigation = true;

					// Clear any existing timeout
					if (navigationTimeout) {
						clearTimeout(navigationTimeout);
					}

					// Remove active class from all slides
					projectNavigationSwiper.slides.forEach((s) => {
						s.classList.remove("active");
					});

					// Add active class to clicked slide
					slide.classList.add("active");

					// Slide to the clicked item
					projectNavigationSwiper.slideTo(index);

					// Scroll to section with offset
					const targetElement = document.getElementById(targetId);
					const elementPosition = targetElement.getBoundingClientRect().top;
					const offsetPosition = elementPosition + window.pageYOffset - 50;

					window.scrollTo({
						top: offsetPosition,
						behavior: "smooth",
					});

					// Reset the flag after animation completes
					navigationTimeout = setTimeout(() => {
						isManualNavigation = false;
					}, 1000); // 1 second timeout
				});
			});
		}

		window.addEventListener("scroll", updateActiveSlide);
	}

	// Update active slide based on scroll position
	function updateActiveSlide() {
		// Skip if manual navigation is in progress
		if (isManualNavigation) return;

		const sections = document.querySelectorAll(".navigation-section");
		const windowHeight = window.innerHeight;
		let currentSectionIndex = null;
		const scrollPosition = window.scrollY;
		const documentHeight = document.documentElement.scrollHeight;
		const threshold = windowHeight * 0.3; // 30% of viewport height for better detection

		// Check if we're at the bottom of the page
		const isAtBottom =
			window.innerHeight + window.scrollY >= documentHeight - 50; // 50px threshold

		if (isAtBottom) {
			// If at bottom, set the last section as active
			currentSectionIndex = sections.length - 1;
		} else {
			// Normal scroll behavior with improved detection
			sections.forEach((section, index) => {
				const rect = section.getBoundingClientRect();
				const sectionTop = rect.top;
				const sectionBottom = rect.bottom;

				// Section is considered active if:
				// 1. The top of the section is above the threshold and bottom is below threshold
				// 2. Or if the section takes up most of the viewport
				if (
					(sectionTop <= threshold && sectionBottom >= threshold) ||
					(sectionTop <= threshold && sectionBottom >= windowHeight - threshold)
				) {
					currentSectionIndex = index;
				}
			});

			// If no section is detected (gaps between sections), find the nearest one
			if (currentSectionIndex === null) {
				let minDistance = Infinity;
				sections.forEach((section, index) => {
					const rect = section.getBoundingClientRect();
					const distance = Math.abs(rect.top);
					if (distance < minDistance) {
						minDistance = distance;
						currentSectionIndex = index;
					}
				});
			}
		}

		if (projectNavigationSwiper && currentSectionIndex !== null) {
			// Remove active class from all slides
			projectNavigationSwiper.slides.forEach((slide) => {
				slide.classList.remove("active");
			});

			// Add active class to current slide
			projectNavigationSwiper.slides[currentSectionIndex].classList.add(
				"active"
			);
			projectNavigationSwiper.slideTo(currentSectionIndex);
		}
	}

	initProjectNavigationSwiper();

	var init = false;
	var featuredSwiper;
	function featuredSwiper() {
		if (window.innerWidth <= 573) {
			if (!init) {
				init = true;
				// Featured Section Slider
				featuredSwiper = new Swiper(".featured-swiper", {
					loop: false,
					slidesPerView: 2.4,
					spaceBetween: 0,
				});
			}
		} else if (init) {
			featuredSwiper.forEach((element) => {
				element.destroy();
			});
			init = false;
		}
	}
	featuredSwiper();
	window.addEventListener("resize", featuredSwiper);

	const swiperMobile = new Swiper(".swiper-mobile-screens", {
		modules: [Navigation, Pagination],
		loop: false,
		slidesPerView: 4,
		spaceBetween: 20,
		// Navigation arrows
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
		//Pagination
		pagination: {
			el: ".swiper-pagination",
			type: "bullets",
			bulletClass: "swiper-bullet",
			bulletActiveClass: "is-active",
		},
		breakpoints: {
			// when window width is >= 320px
			0: {
				slidesPerView: 2.2,
				spaceBetween: 20,
			},
			// when window width is >= 480px
			480: {
				slidesPerView: 3.2,
				spaceBetween: 20,
			},
			// when window width is >= 640px
			640: {
				slidesPerView: 4,
				spaceBetween: 20,
			},
		},
	});

	const swiperDesktop = new Swiper(".swiper-desktop-screens", {
		loop: false,
		slidesPerView: 2,
		modules: [Navigation, Pagination],
		spaceBetween: 20,
		// Navigation arrows
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
		//Pagination
		pagination: {
			el: ".swiper-pagination",
			type: "bullets",
			bulletClass: "swiper-bullet",
			bulletActiveClass: "is-active",
		},
		breakpoints: {
			// when window width is >= 640px
			0: {
				slidesPerView: 1.1,
				spaceBetween: 20,
			},
			640: {
				slidesPerView: 2,
				spaceBetween: 20,
			},
		},
	});

	$(document).ready(function () {
		//PopUp Windows
		function popupWindow(url, title, w, h) {
			var win = window.parent;
			var y = win.top.outerHeight / 2 + win.top.screenY - h / 2;
			var x = win.top.outerWidth / 2 + win.top.screenX - w / 2;
			return win.open(
				url,
				title,
				"toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
					w +
					", height=" +
					h +
					", top=" +
					y +
					", left=" +
					x
			);
		}

		$(".popupWindow").on("click", function (event) {
			var url = $(this).attr("href");
			popupWindow(url, "Share", 600, 600);

			event.preventDefault();
			return false;
		});
	});
})(jQuery);
